<template>
    <div class="search__container">
        <v-text-field
            v-model="query"
            :label="$t('search.input')"
            :loading="loading"
            class="search__field"
            clearable
            dense
            filled
            hide-details="hide-details"
            prepend-inner-icon="mdi-magnify"
            rounded
            single-line
            @click="dialog = (query != null)"
            @input="debouncedSearch"
        >
            <template v-slot:progress>
                <v-progress-circular
                    :size="30"
                    :width="3"
                    absolute
                    class="mt-1 ml-1"
                    color="secondary"
                    indeterminate
                ></v-progress-circular>
            </template>
        </v-text-field>

        <v-btn class="search__btn" icon @click="dialog = true">
            <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-dialog
            v-model="dialog"
            scrollable
            width="500"
        >
            <v-card>
                <v-card-title class="secondary ">
                    <v-text-field
                        v-model="query"
                        :label="$t('search.input')"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                        single-line
                        @input="debouncedSearch"
                    >
                    </v-text-field>
                </v-card-title>
                <v-progress-linear
                    v-if="loading"
                    color="secondary"
                    indeterminate
                ></v-progress-linear>
                <v-card-text class="mt-3">
                    <v-list two-line>
                        <v-list-item-group v-if="hits.length > 0">
                            <template v-for="(item, index) in hits">
                                <router-link :key="item.title" :to="urlFor(item.type, item.uuid)"
                                             class="no-decoration"
                                             @click.native="dialog=false">
                                    <v-list-item>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconFor(item.type) }}
                                            </v-icon>

                                            <v-list-item-action-text
                                                v-text="$t('search.type.' + item.type)"></v-list-item-action-text>
                                        </v-list-item-action>

                                    </v-list-item>

                                    <v-divider
                                        v-if="index < hits.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </router-link>
                            </template>
                        </v-list-item-group>
                        <v-list-item v-else>
                            <v-icon class="mr-5">mdi-magnify-minus</v-icon>
                            {{ $t("search.no_result") }}
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="dialog = false"
                    >
                        {{ $t("search.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Accounting from "@/helpers/Accounting";
import { mdiBankTransfer } from "@mdi/js";
import _ from "lodash";

export default {
    name: "Search",
    data() {
        return {
            icons: {
                mdiBankTransfer
            },
            query: null,
            loading: false,
            dialog: false,
            processingTimeMs: null,
            offset: null,
            limit: null,
            estimatedTotalHits: null,
            nbHits: null,
            hits: []
        };
    },
    created() {
        this.debouncedSearch = _.debounce(this.search, 1000);
    },
    methods: {
        iconFor(type) {
            const icons = {
                "bill": "mdi-receipt-text",
                "invoice": "mdi-receipt-text",
                "customer": "mdi-account",
                "contact": "mdi-account",
                "account": "mdi-bank",
                "category": "mdi-tag",
                "document": "mdi-file-document-outline",
                "quote": "mdi-receipt-text",
                "transaction": this.icons.mdiBankTransfer,
                "vendor": "mdi-account",
                "expense_report": "mdi-receipt-send",
                "credential": "mdi-shield-key",
                "payment": "mdi-wallet"
            };
            return icons[type] !== undefined ? icons[type] : "mdi-help-circle-outline";
        },
        urlFor(type, uuid) {
            const urls = {
                "bill": "/expenses/bills/" + uuid,
                "invoice": "/incomes/invoices/" + uuid,
                "customer": "/contacts/" + uuid,
                "contact": "/contacts/" + uuid,
                "account": "/accounts/" + uuid,
                "category": "/categories/" + uuid,
                "document": "/documents/" + uuid,
                "quote": "/quotes/" + uuid,
                "transaction": "/transactions/" + uuid,
                "vendor": "/contacts/" + uuid,
                "expense_report": "/expenses/expense-reports/" + uuid,
                "credential": "/credentials/" + uuid,
                "payment": "/expenses/payments/" + uuid
            };
            return urls[type] !== undefined ? urls[type] : "/";
        },
        search() {
            if (this.query === null || this.query === "") {
                return;
            }

            this.loading = true;
            Accounting.get("/search?q=" + encodeURI(this.query))
                .then(response => {
                    let data = response.data;

                    this.hits = data.hits;
                    this.processingTimeMs = data.processingTimeMs;
                    this.limit = data.limit;
                    this.offset = data.offset;
                    this.estimatedTotalHits = data.estimatedTotalHits;
                    this.nbHits = data.nbHits;

                    this.loading = false;
                    this.dialog = true;
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.no-decoration {
    text-decoration: none;
}

.search__container {
    margin-bottom: 18px;
    flex-grow: 2;
    display: flex;
    justify-content: end;
    max-width: 400px;
}

.search__btn {
    display: none;
    margin-top: 18.5px;
}

.search__field {
    max-width: 100%;
}

@media (max-width: 899px) {
    .search__field {
        display: none;
    }

    .search__btn {
        display: block;
    }
}
</style>