import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        breadcrumbs: [
            {
                text: "Dashboard",
                to: "/"
            }
        ],
        API_TOKEN: null,
        company: {
            name: null,
            uuid: null
        },
        user: "anonymous@accounting.sh",
        permissions: [],
        // Theses ar fake tokens...
        // API_TOKEN: "TvJNqL6HHcEgpnaYD2ZjhWSTwNFN2YGcRrA2P8MG5pw9g58cWu6rmbpiXJ8TqxmBkCbAZyFrpdErpZWMhWXCAUPVeuG6d7PqqcmK7WameuCEisTPH7SNxTNREZWaAKxj",
        // company: {
        //     uuid: "a0f8197c-db4b-4c7b-a680-493a7de1545f",
        //     name: "Company Name",
        // },
        companies: [],
        alerts: []
    },
    mutations: {
        SET_BREADCRUMBS: (state, breadcrumbs) => {
            state.breadcrumbs = breadcrumbs;
            let company = state.company.name;
            if (company === null) {
                company = "Accounting";
            }
            window.document.title = breadcrumbs[breadcrumbs.length - 1].text + " | " + company;
        },
        SET_TOKEN: (state, token) => {
            state.API_TOKEN = token;
        },
        SET_ALERTS: (state, alerts) => {
            state.alerts = alerts;
        },
        SET_COMPANY: (state, company) => {
            state.company = company;
        },
        SET_COMPANIES: (state, companies) => {
            state.companies = companies;
        },
        SET_USER_EMAIL: (state, email) => {
            state.user = email;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        }
    },
    actions: {}
});
