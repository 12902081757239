<script>
import Accounting from "@/helpers/Accounting";
import DateTime from "@/components/DateTime.vue";

export default {
    name: "NotificationMenu",
    components: {DateTime},
    data() {
        return {
            notifications: [],
            loading: false
        };
    },
    mounted() {
        this.loading = true;
        Accounting.get("/notifications?per_page=5&page=1")
            .then(resp => {
                this.notifications = resp.data.notifications;
            })
            .finally(() => {
                this.loading = false;
            });
    }
};
</script>

<template>
    <v-menu offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :loading="loading"
                class="ml-2 mr-2"
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-bell</v-icon>
            </v-btn>
        </template>
        <v-list v-if="notifications.length > 0">
            <template v-for="e in notifications">
                <v-list-item
                    :key="e.uuid"
                >
                    <v-list-item-content class="pa-3">
                        <v-list-item-title>{{ e.notification.title }} <i class="ml-6">
                            <DateTime :date="new Date(e.notification.date*1000)"></DateTime>
                        </i></v-list-item-title>
                        <p class="mt-2">{{ e.notification.body }}</p>

                        <v-list-item-action v-if="e.notification.actions != null && e.notification.actions.length > 0"
                                            class="d-flex flex-row mt-2 notifications_actions">
                            <template v-for="(act, index) in e.notification.actions">
                                <v-btn v-if="act.type === 'link'" :key="index" :href="act.target"
                                       color="secondary" text>
                                    {{ act.text }}
                                </v-btn>
                            </template>
                        </v-list-item-action>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-list v-else>
            <v-list-item>
                <v-list-item-title>{{ $t("notifications.no_notifications") }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<style scoped>
.notifications_actions {
    flex: 0 !important;
}
</style>