<template>
    <div>
        <v-app v-if="$route.path.startsWith('/login') || $route.path.endsWith('/review')">
            <v-main style="padding: 0px !important;overflow: hidden;">
                <router-view></router-view>
            </v-main>
        </v-app>
        <v-app v-else>
            <!--      Sidebar -->
            <v-navigation-drawer
                v-model="drawer"
                :absolute="mobile"
                :permanent="!mobile"
                :temporary="mobile"
                class="navbar"
            >
                <!-- expand-on-hover -->
                <v-list>
                    <v-list-item class="px-2">
                        <v-list-item-avatar>
                            <v-img :src="$auth.user.picture"></v-img>
                        </v-list-item-avatar>
                    </v-list-item>

                    <v-list-item link @click="settings = true">
                        <v-list-item-content>
                            <v-list-item-title class="title">
                                {{ $auth.user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-icon>mdi-menu-down</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                </v-list>

                <v-divider></v-divider>

                <v-list
                    dense
                    nav
                >

                    <v-list-item v-if="mobile && $store.state.companies.length > 1" link @click="company_select = true">
                        <v-list-item-avatar size="24">
                            <v-img v-if="$store.state.company.logo !== undefined && $store.state.company.logo !== null"
                                   :src="$store.state.company.logo"></v-img>
                            <v-img v-else
                                   src="./assets/images/a.png"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-title>{{ $store.state.company.name }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="mobile && $store.state.companies.length > 1" class="mt-1 mb-1"></v-divider>

                    <div v-for="item in filteredMenu" :key="item">
                        <v-list-item v-if="item.to !== undefined && item.children === undefined"
                                     :exact="item.exact !== undefined ? item.exact : false" :to="item.to">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <v-divider v-else-if="item.divider !== undefined" class="mt-1 mb-1"></v-divider>

                        <v-list-group v-if="item.children !== undefined">
                            <template v-slot:activator>
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </template>

                            <div v-for="itm in item.children" :key="itm">
                                <v-list-item v-if="itm.to !== undefined"
                                             :exact="itm.exact !== undefined ? itm.exact : false" :to="itm.to">
                                    <v-list-item-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        <v-icon class="mr-2">{{ itm.icon }}</v-icon>
                                        {{ itm.title }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-else-if="itm.divider !== undefined" class="mt-1 mb-1"></v-divider>
                            </div>
                        </v-list-group>
                    </div>
                </v-list>

                <template v-if="$store.state.companies.length > 1" v-slot:append>
                    <v-list dense nav>
                        <v-list-item link @click="company_select = true">
                            <v-list-item-avatar size="24">
                                <v-img
                                    v-if="$store.state.company.logo !== undefined && $store.state.company.logo !== null"
                                    :src="$store.state.company.logo"></v-img>
                                <v-img v-else
                                       src="./assets/images/a.png"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-title>{{ $store.state.company.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </template>
            </v-navigation-drawer>

            <!--      Main Content -->
            <v-main>
                <v-toolbar :elevation="0" class="mb-5" outlined style="border-left: 0;border-top:0;border-right:0;">
                    <v-app-bar-nav-icon v-if="mobile" @click="drawer = !drawer"></v-app-bar-nav-icon>
                    <v-chip v-if="Accounting.getUrl().includes('sandbox') || Accounting.getUrl().includes('localhost')"
                            color="orange"
                            outlined>
                        {{ $t("sandbox") }}
                    </v-chip>
                    <v-toolbar-title>
                        <v-breadcrumbs :items="$store.state.breadcrumbs">
                            <template v-slot:divider>
                                <v-icon>mdi-chevron-right</v-icon>
                            </template>
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :disabled="item.disabled"
                                    :href="item.href"
                                    :style="{color: $vuetify.theme.currentTheme.secondary}"
                                    :to="item.to"
                                    :exact="item.exact"
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <Search v-if="Object.values($store.state.permissions).includes('search')"></Search>
                    <NotificationMenu
                        v-if="Object.values($store.state.permissions).includes('list_notification')"></NotificationMenu>
                </v-toolbar>

                <div v-if="mobile" class="mt-5"></div>

                <div class="ml-10 mr-10">
                    <v-alert
                        v-for="error in $store.state.alerts"
                        :key="error"
                        :type="error.type"
                        border="left"
                        dismissible
                    >
                        {{ error.text }}
                    </v-alert>
                </div>

                <router-view class="mb-10"></router-view>
            </v-main>


            <!--        User settings dialog -->
            <v-dialog
                v-model="settings"
                max-width="600px"
            >
                <v-card>
                    <v-card-title class="secondary">
                        <span class="headline text--white">{{ $t("settings.title") }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-switch
                                color="secondary"
                                v-model="dark"
                                :label="$t('settings.dark_mode')"
                            ></v-switch>

                            <v-select
                                v-model="locale"
                                :items="getAvailableLocales()"
                                :label="$t('settings.language')"
                                outlined
                            ></v-select>

                            <v-list nav>
                                <v-list-item href="https://my.stantabcorp.com/account" target="_blank">
                                    <v-list-item-icon>
                                        <v-icon>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ $t("login.account_settings") }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item href="https://my.stantabcorp.com/products/accounting-sandbox"
                                             v-if="!Accounting.getUrl().includes('sandbox')" target="_blank">
                                    <v-list-item-icon>
                                        <v-icon>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ $t("settings.sandbox") }}</v-list-item-title>
                                </v-list-item>
                            </v-list>

                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        0.0.183-14bf18e4
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red"
                            text
                            @click="$auth.logout()"
                        >
                            {{ $t("settings.logout") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!--        Company selection dialog -->
            <v-dialog
                v-model="company_select"
                max-width="390"
            >
                <v-card class="secondary">
                    <v-card-title class="headline text--white">
                        {{ $t("company_select.title") }}
                    </v-card-title>
                    <v-list rounded>
                        <v-list-item-group>
                            <v-list-item v-for="company in $store.state.companies" :key="company.uuid"
                                         link @click="selectCompany(company.uuid)">
                                <v-list-item-avatar size="24">
                                    <v-img v-if="company.logo !== undefined && company.logo !== null"
                                           :src="company.logo"></v-img>
                                    <v-img v-else
                                           src="./assets/images/a.png"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-title>{{ company.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-dialog>

            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-app>
    </div>
</template>

<script>
import { mdiBankTransfer, mdiPiggyBank } from "@mdi/js";
import Accounting from "./helpers/Accounting";
import * as Sentry from "@sentry/vue";
import Search from "@/components/Search.vue";
import NotificationMenu from "@/components/NotificationMenu.vue";

export default {
    name: "App",
    components: {NotificationMenu, Search},
    data: () => ({
        icons: {
            mdiBankTransfer,
            mdiPiggyBank
        },
        settings: false,
        company_select: false,
        dark: false,
        loading: false,
        drawer: false,
        mobile: false,
        locale: null,

        permissions: ["none"],
        menu: []
    }),
    mounted() {

        if (!this.$route.path.startsWith("/login") && !this.$auth.user.email) {
            this.loading = false;
        }

        this.windowResize();
        if (this.$ls.get("theme") == null) {
            this.$ls.set("theme", this.$vuetify.theme.dark);
        } else {
            this.$vuetify.theme.dark = this.$ls.get("theme");
        }
        this.dark = this.$vuetify.theme.dark;

        if (this.$ls.get("language") == null) {
            this.locale = this.getAvailableLocales()[0];
        } else {
            this.locale = this.$ls.get("language");
        }

        this.loadMenu();

        this.$router.afterEach((to) => {
            if (to.path === "/login") {
                return;
            }

            if (this.$auth.isAuthenticated) {
                this.getAccountingToken();
            }
        });
    },
    created() {
        window.addEventListener("resize", this.windowResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.windowResize);
    },
    computed: {
        Accounting() {
            return Accounting;
        },
        filteredMenu() {
            if (this.$store.state.permissions.length === 0) {
                this.requestUserInfos();
            }

            let finalMenu = [];
            for (const elm of this.menu) {
                if (elm.permissions === undefined) {
                    finalMenu.push(elm);
                    continue;
                }

                if (elm.children !== undefined) {
                    let parent = [];
                    for (const child of elm.children) {
                        if (child.permissions.includes("*")) {
                            parent.push(child);
                            continue;
                        }
                        for (const permission of child.permissions) {
                            if (Object.values(this.$store.state.permissions).includes(permission)) {
                                parent.push(child);
                                break;
                            }
                        }
                    }

                    if (parent.length > 0) {
                        finalMenu.push(parent);
                    }
                } else {
                    if (elm.permissions.includes("*")) {
                        finalMenu.push(elm);
                        continue;
                    }
                    for (const permission of elm.permissions) {
                        if (Object.values(this.$store.state.permissions).includes(permission)) {
                            finalMenu.push(elm);
                            break;
                        }
                    }
                }
            }

            return this.removeDividers(finalMenu);
        }
    },
    methods: {
        removeDividers(menu) {
            let nMenu = [];

            for (let i = 0; i < menu.length; i++) {
                if (menu[i].divider === undefined || menu[i - 1].divider === undefined) {
                    nMenu.push(menu[i]);
                }
            }

            return nMenu;
        },
        loadMenu() {
            this.menu = [
                {
                    to: "/",
                    icon: "mdi-home",
                    title: this.$t("menu.home"),
                    permissions: ["*"],
                    exact: true
                },
                {
                    to: "/contacts",
                    icon: "mdi-account",
                    title: this.$t("menu.contacts"),
                    permissions: ["list.contacts", "add.contact", "view.contact", "edit.contact", "delete.contact"]
                },
                {divider: true},
                {
                    to: "/incomes/invoices",
                    icon: "mdi-receipt-text",
                    title: this.$t("menu.invoices"),
                    permissions: ["list.invoices", "add.invoice", "view.invoice", "edit.invoice", "add.invoice.payment", "edit.invoice.revenues", "invoice.delete"]
                },
                {
                    to: "/incomes/revenues",
                    icon: this.icons.mdiPiggyBank,
                    title: this.$t("menu.revenues"),
                    permissions: ["list.revenues", "add.revenue", "view.revenue", "edit.revenue"]
                },
                {divider: true},
                {
                    to: "/expenses/payments",
                    icon: "mdi-wallet",
                    title: this.$t("menu.payments"),
                    permissions: ["list.payments", "add.payment", "view.payment", "edit.payment", "delete.payment"]
                },
                {
                    to: "/expenses/bills",
                    icon: "mdi-receipt-text",
                    title: this.$t("menu.bills"),
                    permissions: ["list.bills", "add.bill", "view.bill", "edit.bill", "delete.bill"]
                },
                {
                    to: "/expenses/expense-reports",
                    icon: "mdi-receipt-send",
                    title: this.$t("menu.expense_reports"),
                    permissions: ["expense_report.list", "expense_report.add", "expense_report.view", "expense_report.edit", "expense_report.delete"]
                },
                {divider: true},
                {
                    to: "/accounts",
                    icon: "mdi-bank",
                    title: this.$t("menu.accounts"),
                    permissions: ["list.accounts", "add.account", "view.account", "edit.account", "delete.account"]
                },
                {
                    to: "/transfers",
                    icon: this.icons.mdiBankTransfer,
                    title: this.$t("menu.transfers"),
                    permissions: ["list.transfers", "add.transfer", "view.transfer", "edit.transfer", "delete.transfer"]
                },
                {divider: true},
                {
                    to: "/categories",
                    icon: "mdi-tag",
                    title: this.$t("menu.categories"),
                    permissions: ["list.categories", "add.category", "view.category", "edit.category", "delete.category"]
                },
                {
                    to: "/documents",
                    icon: "mdi-file-document-outline",
                    title: this.$t("menu.documents"),
                    permissions: ["list.documents", "add.document", "view.document", "edit.document", "delete.document"]
                },
                {
                    to: "/quotes",
                    icon: "mdi-receipt-text",
                    title: this.$t("menu.quotes"),
                    permissions: ["list.quotes", "add.quote", "view.quote", "edit.quote", "delete.quote"]
                },
                {
                    to: "/receipts",
                    icon: "mdi-receipt-text",
                    title: this.$t("menu.receipts"),
                    permissions: ["list.receipts", "add.receipt", "view.receipt", "edit.receipt", "delete.receipt"]
                },
                {divider: true},
                {
                    to: "#",
                    icon: "mdi-cog",
                    title: this.$t("menu.settings"),
                    children: [
                        {
                            to: "/credentials",
                            icon: "mdi-shield-key",
                            title: this.$t("menu.credentials"),
                            permissions: ["list.credentials", "add.credential", "view.credential", "edit.credential", "delete.credential"]
                        },
                        {
                            to: "/export",
                            icon: "mdi-cloud-download",
                            title: this.$t("menu.export"),
                            permissions: ["export"]
                        },
                        {
                            to: "/logs",
                            icon: "mdi-text",
                            title: this.$t("menu.logs"),
                            permissions: ["log.view"]
                        },
                        {
                            to: "/webhooks",
                            icon: "mdi-earth",
                            title: this.$t("menu.webhooks"),
                            permissions: ["list.webhooks", "add.webhook", "view.webhook", "edit.webhook", "delete.webhook"]
                        },
                        {
                            to: "/company/settings",
                            icon: "mdi-cog",
                            title: this.$t("menu.company_settings"),
                            permissions: ["*"]
                        },
                        {
                            to: "/docs",
                            icon: "mdi-api",
                            title: this.$t("menu.api_doc"),
                            permissions: ["*"]
                        }
                    ]
                }
            ];
        },
        getAvailableLocales() {
            let locales = [];

            for (const locale in this.$i18n.availableLocales) {
                locales.push({
                    text: this.$t("locales." + this.$i18n.availableLocales[locale]),
                    value: this.$i18n.availableLocales[locale]
                });
            }

            return locales;
        },
        windowResize() {
            this.mobile = window.innerWidth < 900;
        },
        getAccountingToken() {
            if (this.$store.state.API_TOKEN == null || this.$store.state.company.name == null || this.$store.state.company.uuid == null) {
                this.requestToken();
            } else {
                this.loading = true;
                this.requestUserInfos();
            }
        },
        async requestUserInfos() {
            // this.loading = true;
            await Accounting.get("/me")
                .then(response => {
                    this.permissions = response.data.permissions;
                    this.$store.commit("SET_PERMISSIONS", this.permissions);
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.requestToken();
                });
        },
        loadCompaniesCustomization() {
            let promises = [];
            let customization = {};
            const companies = this.$store.state.companies;
            for (const index in companies) {
                promises.push(Accounting.get("/companies/" + companies[index].uuid + "/customization")
                    .then(resp => {
                        customization[companies[index].uuid] = {
                            color: resp.data.color,
                            logo: resp.data.logo
                        };
                    }));
            }
            Promise.all(promises).finally(() => {
                for (const company in companies) {
                    if (customization[companies[company]] !== undefined) {
                        companies[company].color = customization[companies[company]].color;
                        companies[company].logo = customization[companies[company]].logo;
                    }
                }
                this.$store.commit("SET_COMPANIES", companies);
                this.updateCompanyStore(this.$store.state.company.uuid);
            });
        },
        requestToken() {
            this.loading = true;
            this.$auth.getTokenSilently().then(token => {
                Accounting.getMyStantabcorpToken(token)
                    .then(response => {
                        this.$store.commit("SET_COMPANIES", response.data.companies);
                        this.loadCompaniesCustomization();
                        Sentry.setUser({
                            username: this.$auth.user.name,
                            email: this.$auth.user.email,
                            id: this.$auth.user.id
                        });
                        this.$store.commit("SET_USER_EMAIL", this.$auth.user.email);

                        if (response.data.companies.length === 0) {
                            this.$router.push("/no-company");
                        } else {
                            if (this.$ls.get("selected__company") == null) {
                                this.company_select = true;
                            } else {
                                this.updateCompanyStore(this.$ls.get("selected__company"));
                            }
                        }
                        this.loading = false;

                        this.requestUserInfos();

                    })
                    .catch(() => {
                        this.$auth.logout();
                    });
            });
        },
        updateCompanyStore(company) {
            const companies = this.$store.state.companies;
            for (const index in companies) {
                if (companies[index].uuid === company) {
                    this.$store.commit("SET_COMPANY", {
                        name: companies[index].name,
                        uuid: company,
                        color: companies[index].color,
                        logo: companies[index].logo
                    });
                    this.$store.commit("SET_TOKEN", companies[index].token);
                    this.$ls.set("selected__company", company);

                    if (companies[index].color !== undefined && companies[index].color !== null) {
                        this.$vuetify.theme.themes.light.secondary = companies[index].color;
                        this.$vuetify.theme.themes.dark.secondary = companies[index].color;
                    }
                }
            }
        },
        selectCompany(company) {
            this.loading = true;
            this.updateCompanyStore(company);

            this.$router.push({
                path: "/redirect",
                query: {
                    path: this.$route.path
                }
            }).catch(() => {
            });
            this.company_select = false;
            this.loading = false;
        }
    },
    watch: {
        dark: function (newVal) {
            this.$vuetify.theme.dark = newVal;
            this.$ls.set("theme", this.$vuetify.theme.dark);
            if (newVal) {
                document.body.classList.add("app--dark");
            } else {
                document.body.classList.remove("app--dark");
            }
        },
        locale: function (val) {
            this.$i18n.locale = val;
            this.$ls.set("language", val);
            this.loadMenu();
        }
    }
};
</script>

<style>
.v-application--wrap {
    flex-direction: row !important;
    height: 100vh;
}

.v-application, body, html {
    overflow: hidden !important;
}

.v-main {
    padding-bottom: 10px !important;
    flex: 0 auto !important;
    width: 100%;
    overflow-y: auto;
}

.v-card > :first-child:not(.v-btn):not(.v-chip) {
    border-radius: 0 !important;
}

.next-step {
    position: absolute !important;
    right: calc(50% - 335px);
    top: calc(50% - 100px);
    z-index: 9999;
}

.theme--light.v-application .primary--text {
    color: rgba(0, 0, 0, 0.87) !important;
    caret-color: rgba(0, 0, 0, 0.87) !important;
}

.theme--light.v-application .primary--text .v-icon {
    color: rgba(0, 0, 0, 0.54) !important;
    caret-color: rgba(0, 0, 0, 0.54) !important;
}

.theme--dark.v-application .primary--text {
    color: #FFF !important;
    caret-color: #FFF !important;
}

.text--white {
    color: #FFFFFF !important;
}

.navbar {
    flex: none !important;
}

.mobile_button {
    top: 7px;
    left: 10px;
}

.page__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 24px;
}

.search__field {
    height: 40px;
    min-width: 200px !important;
    padding-top: 8px !important;
}

.mobile__margin {
    margin-left: 50px !important;
}

a.v-breadcrumbs__item:not(.v-breadcrumbs__item--disabled) {
    color: inherit !important;
}
</style>
