import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: "#1A2975",
                secondary: "#344F9B",
                accent: "#D8600E",
                error: "#B22812"
            },
            dark: {
                primary: "#1A2975",
                secondary: "#344F9B",
                accent: "#D8600E",
                error: "#B22812"
            }
        }
    }
});
