import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./auth/authGuard";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: () => import("./views/Dashboard"),
            name: "dashboard",
            beforeEnter: authGuard
        },
        {
            path: "/redirect",
            component: () => import("./views/Redirect"),
            name: "redirect",
            beforeEnter: authGuard
        },
        {
            path: "/login",
            component: () => import("./views/Login"),
            name: "login"
        },
        {
            path: "/contacts",
            component: () => import("./views/Contacts/List"),
            name: "contacts",
            beforeEnter: authGuard
        },
        {
            path: "/contacts/:uuid",
            component: () => import("./views/Contacts/Details"),
            name: "contacts.details",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/customers",
            redirect: "/contacts",
            name: "customers",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/customers/:uuid",
            redirect: to => {
                return {path: "/contacts/" + to.params.uuid};
            },
            name: "customers.details",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/invoices",
            component: () => import("./views/Invoices/List"),
            name: "invoices",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/invoices/:uuid",
            component: () => import("./views/Invoices/Details"),
            name: "invoices.details",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/invoices/:uuid/payments",
            component: () => import("./views/Invoices/Payments"),
            name: "invoices.payments",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/invoices/:uuid/payments/:id",
            component: () => import("./views/Invoices/EditPayment.vue"),
            name: "invoices.payments.edit",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/revenues",
            component: () => import("./views/Revenues/List"),
            name: "revenues",
            beforeEnter: authGuard
        },
        {
            path: "/incomes/revenues/:uuid",
            component: () => import("./views/Revenues/Details"),
            name: "revenues.details",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/payments",
            component: () => import("./views/Payments/List"),
            name: "payments",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/payments/:uuid",
            component: () => import("./views/Payments/Details"),
            name: "payments.details",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/bills",
            component: () => import("./views/Bills/List"),
            name: "bills",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/bills/:uuid",
            component: () => import("./views/Bills/Details"),
            name: "bills.details",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/expense-reports",
            component: () => import("./views/ExpenseReports/List"),
            name: "expense_reports",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/expense-reports/:uuid",
            component: () => import("./views/ExpenseReports/Details"),
            name: "expense_reports.details",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/vendors",
            redirect: "/contacts",
            name: "vendors",
            beforeEnter: authGuard
        },
        {
            path: "/expenses/vendors/:uuid",
            redirect: to => {
                return {path: "/contacts/" + to.params.uuid};
            },
            name: "vendors.details",
            beforeEnter: authGuard
        },
        {
            path: "/accounts",
            component: () => import("./views/Accounts/List"),
            name: "accounts",
            beforeEnter: authGuard
        },
        {
            path: "/accounts/:uuid",
            component: () => import("./views/Accounts/Details"),
            name: "accounts.details",
            beforeEnter: authGuard
        },
        {
            path: "/accounts/:uuid/transactions",
            component: () => import("./views/Accounts/Transactions/List"),
            name: "accounts.transactions",
            beforeEnter: authGuard
        },
        {
            path: "/accounts/:uuid/transactions/:id",
            component: () => import("./views/Accounts/Transactions/Details"),
            name: "accounts.transactions",
            beforeEnter: authGuard
        },
        {
            path: "/categories",
            component: () => import("./views/Categories/List"),
            name: "categories",
            beforeEnter: authGuard
        },
        {
            path: "/categories/:uuid",
            component: () => import("./views/Categories/Details"),
            name: "categories.details",
            beforeEnter: authGuard
        },
        {
            path: "/documents",
            component: () => import("./views/Documents/List"),
            name: "documents",
            beforeEnter: authGuard
        },
        {
            path: "/documents/:uuid",
            component: () => import("./views/Documents/Details"),
            name: "documents.details",
            beforeEnter: authGuard
        },
        {
            path: "/documents/:uuid/review",
            component: () => import("./views/Documents/Review.vue"),
            name: "documents.review",
            beforeEnter: authGuard
        },
        {
            path: "/quotes",
            component: () => import("./views/Quotes/List"),
            name: "quotes",
            beforeEnter: authGuard
        },
        {
            path: "/quotes/:uuid",
            component: () => import("./views/Quotes/Details"),
            name: "quotes.details",
            beforeEnter: authGuard
        },
        {
            path: "/receipts",
            component: () => import("./views/Receipts/List"),
            name: "receipts",
            beforeEnter: authGuard
        },
        {
            path: "/receipts/:uuid",
            component: () => import("./views/Receipts/Details"),
            name: "receipts.details",
            beforeEnter: authGuard
        },
        {
            path: "/transfers",
            component: () => import("./views/Transfers/List"),
            name: "transfers",
            beforeEnter: authGuard
        },
        {
            path: "/transfers/:uuid",
            component: () => import("./views/Transfers/Details"),
            name: "transfers.details",
            beforeEnter: authGuard
        },
        {
            path: "/credentials",
            component: () => import("./views/Credentials/List"),
            name: "credentials",
            beforeEnter: authGuard
        },
        {
            path: "/credentials/:uuid",
            component: () => import("./views/Credentials/Details"),
            name: "credentials.details",
            beforeEnter: authGuard
        },
        {
            path: "/export",
            component: () => import("./views/Export"),
            name: "export",
            beforeEnter: authGuard
        },
        {
            path: "/logs",
            component: () => import("./views/Log"),
            name: "logs",
            beforeEnter: authGuard
        },
        {
            path: "/webhooks",
            component: () => import("./views/Webhooks/List"),
            name: "webhooks",
            beforeEnter: authGuard
        },
        {
            path: "/webhooks/:uuid",
            component: () => import("./views/Webhooks/Details"),
            name: "webhooks.details",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings",
            component: () => import("./views/Settings/Home.vue"),
            name: "company.settings",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/details",
            component: () => import("./views/Settings/Details.vue"),
            name: "company.settings.details",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/notifications",
            component: () => import("./views/Settings/Notifications.vue"),
            name: "company.settings.notifications",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/customisation",
            component: () => import("./views/Settings/Customisation.vue"),
            name: "company.settings.customisation",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/expense-reports",
            component: () => import("./views/Settings/ExpenseReport.vue"),
            name: "company.settings.expense-reports",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/accounting-codes",
            component: () => import("./views/Settings/AccountingCodes.vue"),
            name: "company.settings.accounting-codes",
            beforeEnter: authGuard
        },
        {
            path: "/company/settings/oauth",
            component: () => import("./views/Settings/OAuthConfig.vue"),
            name: "company.settings.oauth",
            beforeEnter: authGuard
        },
        {
            path: "/docs",
            component: () => import("./views/Documentation"),
            name: "documentation",
            beforeEnter: authGuard
        },

        {
            path: "/no-company",
            component: () => import("./views/NoCompany.vue"),
            name: "no-company",
            beforeEnter: authGuard
        },
        {
            path: "*",
            component: () => import("./views/Error404"),
            name: "error-404",
            beforeEnter: authGuard
        }
    ]
});
