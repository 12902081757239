import axios from "axios";
import store from "../store";
import axiosRetry from "axios-retry";

const API_URL = "https://api.accounting.sh"; // Note: This value will be changed in the CI.

// eslint-disable-next-line no-unused-vars
const MAX_RETRY = 3;
// eslint-disable-next-line no-unused-vars
const RETRY_DELAY = 1000;

export default {

    getUrl() {
        return API_URL;
    },

    // eslint-disable-next-line no-unused-vars
    get(url, retries = 0) {
        return new Promise((resolve, reject) => {
            this.configureAxiosRetry();
            axios.get(API_URL + url, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN
                }
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    getMyStantabcorpToken(token) {
        return new Promise((resolve, reject) => {
            axios.post(API_URL + "/userveria", {
                token
            }, {}).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    post(url, body, retries = 0) {
        return new Promise((resolve, reject) => {
            axios.post(API_URL + url, body, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN,
                    "X-Audit-Comment": "Action performed on the panel by " + store.state.user
                }
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    put(url, body, retries = 0) {
        return new Promise((resolve, reject) => {
            axios.put(API_URL + url, body, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN,
                    "X-Audit-Comment": "Action performed on the panel by " + store.state.user
                }
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    upload(url, body, retries = 0) {
        return new Promise((resolve, reject) => {
            axios.post(API_URL + url, body, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN,
                    "Content-Type": "multipart/form-data",
                    "X-Audit-Comment": "Action performed on the panel by " + store.state.user
                }
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    blob(url, retries = 0) {
        return new Promise((resolve, reject) => {

            let headers = new Headers();

            headers.set("Authorization", "Bearer " + store.state.API_TOKEN);

            let init = {
                method: "GET",
                headers,
                mode: "cors",
                cache: "default"
            };


            fetch(API_URL + url, init)
                .then(response => {
                    resolve(response.blob());
                }).catch(error => {
                reject(error);
            });

            /*axios.get(API_URL + url, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN,
                    "Content-Type": "multipart/form-data"
                }
            })*/

        });
    },

    // eslint-disable-next-line no-unused-vars
    download(url, documentName, retries = 0) {
        return new Promise((resolve, reject) => {
            this.blob(url)
                .then(blob => {
                    let object = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = object;
                    link.download = documentName;
                    document.body.appendChild(link);
                    link.dispatchEvent(
                        new MouseEvent("click", {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );
                    document.body.removeChild(link);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    },

    // eslint-disable-next-line no-unused-vars
    delete(url, retries = 0) {
        return new Promise((resolve, reject) => {
            axios.delete(API_URL + url, {
                headers: {
                    Authorization: "Bearer " + store.state.API_TOKEN,
                    "X-Audit-Comment": "Action performed on the panel by " + store.state.user
                }
            }).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    configureAxiosRetry() {
        axiosRetry(axios, {
            retries: 5,
            retryDelay: () => {
                return 3000;
            },
            retryCondition: (error) => {
                return error.response !== undefined && (axiosRetry.isNetworkError(error) || error.response.status === 401);
            },
            onRetry: (retryCount, error, requestConfig) => {
                requestConfig.headers["Authorization"] = "Bearer " + store.state.API_TOKEN;
            }
        });
    }

};