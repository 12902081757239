<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <timeago
                                :datetime="date"
                                :locale="$i18n.locale"
                            ></timeago>
                        </span>
        </template>
        <span>{{ date }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: "DateTime",
    props: ["date"]
};
</script>

<style scoped>

</style>