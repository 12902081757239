import Vue from "vue";
import App from "./App.vue";
import vuetify from "./vuetify";

import router from "./router";
import store from "./store";
import Storage from "vue-ls";
import VueI18n from "vue-i18n";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/css/global.css";
import axios from "axios";
import VueTimeago from "vue-timeago";

// my stantabcorp
import { MyStantabcorpPlugin } from "./auth";
// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";

let auth_config = require("../auth.prod.json");
let domain = auth_config.domain;
let product = auth_config.product;

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        en: require("./assets/locales/en.json"),
        fr: require("./assets/locales/fr.json")
    }
});

Vue.use(Storage, {
    namespace: "accounting__"
});

Vue.use(Vuelidate);

Vue.prototype.$axios = axios;

// my stantabcorp
Vue.use(MyStantabcorpPlugin, {
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
    domain: domain,
    product: product
});

// Timeago
Vue.use(VueTimeago, {
    name: "Timeago",
    locale: "en",
    locales: {
        fr: require("date-fns/locale/fr")
    }
});

Vue.use(VueMonacoEditorPlugin, {
    paths: {
        // The recommended CDN config
        vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.45.0/min/vs"
    }
});

try {
    Sentry.init({
        Vue,
        dsn: "https://c57abdf11be84e0fa2a8482049aeb8d3@sentry.stantabcorp.net/3",
        integrations: [new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })],
        release: "VERSION_HERE",
        tracesSampleRate: 0.5,
        logErrors: process.env.NODE_ENV !== "production",
        beforeSend(event) {
            if (event.exception && window.location.hostname !== "localhost") {
                // Sentry.showReportDialog({eventId: event.event_id});
            }
            return event;
        }
    });
} catch (e) {
    console.error(e);
}


new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
