import Vue from "vue";

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const MY_URL = "https://my.stantabcorp.com";

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useMyStantabcorp = ({
                                     onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                                     domain: domain = MY_URL,
                                     product
                                     // redirectUri = window.location.origin === "app://." ? "app://./" : window.location.origin,
                                     // ...options
                                 }) => {
    if (instance) return instance;

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true, isAuthenticated: false, user: {}, error: null
            };
        }, methods: {
            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    let searchParams = new URLSearchParams(window.location.search);
                    if (!searchParams.has("token")) {
                        throw new Error("Token not present in url");
                    }

                    // const formData = new FormData();
                    // formData.set("token", searchParams.get("token"));

                    const resp = await fetch(domain + "/auth/me", {
                        method: "post",
                        body: new URLSearchParams({
                            "token": searchParams.get("token")
                        }),
                        headers: {
                            "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                        }
                    }).then(resp => {
                        return resp.json();
                    }).catch(() => {
                        throw new Error("Error while requesting user details");
                    });

                    if (resp !== undefined) {
                        let name = resp["user"]["first_name"] + " " + resp["user"]["last_name"];
                        if (!resp["user"]["first_name"] && !resp["user"]["last_name"]) {
                            name = resp["user"]["email"];
                        }

                        this.user = {
                            picture: resp["user"]["avatar"],
                            username: resp["user"]["username"],
                            email: resp["user"]["email"],
                            name: name,
                            id: resp["user"]["email"]
                        };
                        this.$ls.set("login__token", searchParams.get("token"));
                        this.isAuthenticated = true;
                        this.error = null;
                    } else {
                        this.isAuthenticated = true;
                        this.error = new Error("Error while requesting user details from my stantabcorp.");
                    }

                    if (this.$ls.get("login__state") != null) {
                        const state = this.$ls.get("login__state");
                        this.$ls.set("login__state", null);
                        return state;
                    }

                    return null;
                } catch (e) {
                    this.error = e;
                } finally {
                    this.loading = false;
                }
            }, /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                this.$ls.set("login__state", null);
                if (o) {
                    this.$ls.set("login__state", o);
                }
                window.location = domain + "/auth/login/" + product;
            }, /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            // eslint-disable-next-line no-unused-vars
            getTokenSilently(o) {
                return new Promise((r) => {
                    if (this.$ls.get("login__token") == null) {
                        this.loginWithRedirect(o);
                    }
                    r(this.$ls.get("login__token"));
                });
            }, /** Logs the user out and removes their session on the authorization server */
            // eslint-disable-next-line no-unused-vars
            logout(o) {
                this.$ls.set("login__token", null);
                window.location = domain;
            }
        }, /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            // Create a new instance of the SDK client using members of the given options object
            try {
                // If the user is returning to the app after authentication..
                if (window.location.search.includes("token=")) {
                    // handle the redirect and retrieve tokens
                    const {appState} = await this.handleRedirectCallback();

                    this.error = null;

                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    onRedirectCallback(appState);
                }
            } catch (e) {
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                // this.isAuthenticated = await this.auth0Client.isAuthenticated();
                // this.user = await this.auth0Client.getUser();
                this.loading = false;
            }
        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const MyStantabcorpPlugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useMyStantabcorp(options);
    }
};